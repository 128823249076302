import store from '@/state/store'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login1'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    // component: () => import('../views/pages/account/register - Copy'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  }, 
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/index')
  },
  {
    path: '/foodServiceHome',
    name: 'foodServiceHome',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/foodServiceHome')
  },
  {
    path: '/profile/:profileType',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/profile')
  },
  {
    path: '/restaurants/:type',
    name: 'restaurants',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/meals/restaurants')
  },

  {
    path: '/restaurantCollections/:type/:id',
    name: 'restaurantCollections',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/meals/restaurantCollections')
  },

  
  {
    path: '/restaurantDetails/:id/:mealType',
    name: 'restaurantDetails',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/meals/restaurantDetails')
  },
  {
    path: '/restaurantDetails/:id',
    name: 'restaurantDetailByShort',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/meals/restaurantDetails')
  },
  {
    path: '/cafeterias',
    name: 'cafeterias',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/meals/cafeterias')
  },
  // {
  //   path: '/deliveryDetails',
  //   name: 'deliveryDetails',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/Delivery/deliveryDetails')
  // },
  {
    path: '/checkOut',
    name: 'checkOut',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/Delivery/checkOut')
  },
  {
    path: '/orderDetails/:pageType',
    name: 'orderDetails',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/account/orderDetails')
  },
  {
    path: '/policies/:policy',
    name: 'policies',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/policies/policies')
  },
  {
    path: '/corporate',
    name: 'corporate',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/corporate')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/aboutUs')
  },

  {
    path: '/paymentInfo',
    name: 'paymentInfo',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/Delivery/pending-order-details')
  },

]